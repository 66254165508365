<div id="page-container">
    <div class="fullScreen">
      <div class="account-header">
        <div *ngIf="maxVoters === 10" style="display: flex; justify-content: space-between; height: 100%">
          <span>
            <p class="banner-text" id="txt">
              {{'POLL-DASHBOARD.HEADBAND.MESSAGE-CREATION' | translate}}
            </p>
          </span>
          <div style="height: 100%; margin-right: 24px; display: flex;">
            <span >
              <p style="margin: 0;" class="banner-text" id="txt2">
                {{accountName}}
              </p>
            </span>
            <img src="assets/power_settings_new_24px.svg" class="power-button" (click)="logout()"
                 style="cursor: pointer; margin-left: 20px;" loading="lazy">
          </div>
        </div>

        <div *ngIf="maxVoters > 10" style="margin-right: 24px; height: 100%; display: flex; justify-content: space-between;">
          <div *ngIf="hasHotline else sub">
            <p class="banner-text hotline-display">
              {{'POLL-DASHBOARD.HEADBAND.PHONE' | translate}}
            </p>
          </div>
          <ng-template #sub>
            <div></div>
          </ng-template>

          <span>
            <p class="banner-text" *ngIf="licenseTime == 'D'">
              {{accountName}} - {{'POLL-DASHBOARD.HEADBAND.EVENTS' | translate}}
            </p>
            <p class="banner-text" *ngIf="licenseTime == 'DM'">
              {{accountName}} - {{eventCounter}} {{'POLL-DASHBOARD.HEADBAND.PACK' | translate}}
            </p>
            <p class="banner-text" *ngIf="licenseTime == 'Y'">
              {{accountName}} - {{'POLL-DASHBOARD.HEADBAND.YEARLY' | translate}}
            </p>
            <img src="assets/power_settings_new_24px.svg" (click)="logout()" loading="lazy"
                 style="cursor: pointer; margin-left: 20px; height: 24px; width:24px; margin-top: 7px">
          </span>

        </div>
      </div>

    </div>
  <div class = "profile-page">
    <div class = "left-side">
      <img src="assets/v8te.png" id="v8te-logo" style="margin-top: 60px;" (click)="backArrow()" loading="lazy">
      <div style="height: 40px"></div>
      <div class="profile-tab" (click)="setStatus(0)">
        <img *ngIf="status === 0" src="assets/profile-account-sel.svg">
        <img *ngIf="status !== 0" src="assets/profile-account-desel.svg">
        <div [ngClass]="status == 0 ? 'tab-active' : 'tab-inactive'">{{'PROFILE.ACCOUNT-TITLE' | translate}}</div>
      </div>
      <div class="profile-tab" *ngIf="shouldDisplayChangePassword()" (click)="setStatus(1)">
        <img *ngIf="status === 1" src="assets/profile-pass-sel.svg">
        <img *ngIf="status !== 1" src="assets/profile-pass-desel.svg">
        <div [ngClass]="status == 1 ? 'tab-active' : 'tab-inactive'">{{'PROFILE.PASS-TITLE' | translate}}</div>
      </div>
      <div class="profile-tab" (click)="setStatus(2)">
        <img *ngIf="status === 2" src="assets/profile-invoices-sel.svg">
        <img *ngIf="status !== 2" src="assets/profile-invoices-unsel.svg">
        <div [ngClass]="status == 2 ? 'tab-active' : 'tab-inactive'">{{'PROFILE.OFFER-TITLE' | translate}}</div>
      </div>
    </div>
    <div class = "right-side">
      <div class="arrow-container" style="display: flex; margin-top: 30px;">
        <img (click)="backArrow()" data-cy="back-button" src="assets/arrow-left.svg" style="cursor: pointer;" loading="lazy">
        <div style="width: 5px"></div>
        <h3 *ngIf="status == 0" class="title-main">{{'PROFILE.ACCOUNT-TITLE' | translate}}</h3>
        <h3 *ngIf="status == 1" class="title-main">{{'PROFILE.PASS-TITLE' | translate}}</h3>
        <h3 *ngIf="status == 2" class="title-main">{{'PROFILE.OFFER-TITLE' | translate}}</h3>
      </div>
      <div *ngIf="status === 0" class="data-holder">
        <div *ngIf="shouldDisplayChangePassword()">
          <div class="input-title">{{'PROFILE.EMAIL-NEW' | translate}}</div>
          <textarea id="email-new" class="name" maxlength="140" placeholder="{{'CHANGE-VOTER.MAIL-NEW' | translate}}"></textarea>
          <div class="input-title">{{'PROFILE.EMAIL-CONFIRM' | translate}}</div>
          <textarea id="email-new-confirm" class="name" maxlength="140" placeholder="{{'CHANGE-VOTER.MAIL-CONFIRM' | translate}}"></textarea>
          <div class="input-title">{{'PROFILE.EMAIL' | translate}}</div>
          <textarea id="email" class="name" maxlength="140" value="{{authenticationService.getUserData()}}" readonly></textarea>
          <button class="blue-button" (click)="changeEmail()">{{'PROFILE.UPDATE' | translate}}</button>
        </div>
        <div (click)="logout()" class="red-link">{{'PROFILE.LOGOUT' | translate}}</div>
        <div (click)="displayDeleteWarning()" class="gray-link">{{'PROFILE.DELETE-ACCOUNT' | translate}}</div>
      </div>
      <div *ngIf="status === 1" class="data-holder">
        <div class="gray-description">{{'PROFILE.CHANGE-PASSWORD' | translate}}</div>
        <div style="width: 100%; display: flex; justify-content: center; margin-top: 16px">
          <button class="blue-button" (click)="changePassword()">{{'PROFILE.UPDATE' | translate}}</button>
        </div>
      </div>
      <div *ngIf="status === 2" class="data-holder" style="width: 50%">
        <div class="input-title">{{'PROFILE.OFFER-HEADER' | translate}}</div>
        <div class="gray-description" style="white-space: pre-wrap; margin-top: 18px">{{offer}}</div>
        <div style="height: 16px;"></div>
        <div *ngIf="invoices.length > 0" class="input-title">{{'PROFILE.OFFER-INVOICES' | translate}}</div>
        <a *ngFor="let invoice of invoices" href="{{invoice}}}" style="overflow: hidden; display: block">{{invoice}}<br></a>
      </div>
    </div>
  </div>
  <vc-warning-popup *ngIf="shouldDisplayDeletionWarning" title="{{ 'VOTERS-INVALID-POPUP.TITLE-1' | translate}}" firstText="{{'PROFILE.DELETE-MESSAGE' | translate}}"
  deleteButton="true" cancelButton="true" (endDetailsPopup)="deleteAccount($event)"></vc-warning-popup>
</div>
