<div class="fullScreen">
  <div class="account-header">
    <div *ngIf="maxVoters === 10" style="display: flex; justify-content: space-between; height: 40px; align-items: center;">
      <span>
        <p class="banner-text" id="txt">
          {{'POLL-DASHBOARD.HEADBAND.MESSAGE-CREATION' | translate}}
        </p>
      </span>
      <div style="height: 40px; margin-right: 24px; display: flex; align-items: center;">
        <img src="assets/profile.svg" style="cursor: pointer; margin-left: 20px;" loading="lazy" height="18" width="20" (click)="goToProfile()">
        <span >
          <p style="margin: 0;" class="banner-text" id="txt2">
            {{accountName}}
          </p>
        </span>
        <img src="assets/power_settings_new_24px.svg" class="power-button" (click)="logout()"
          style="cursor: pointer; margin-left: 20px;" loading="lazy">
      </div>
    </div>

    <div *ngIf="maxVoters > 10" style="margin-right: 24px; height: 100%; display: flex; justify-content: space-between; align-items: center;">
      <button *ngIf="!hasHotline else hotline" class="button-change" [ngClass]="['green-button', 'button-change']"
         (click)="triggerHotlinePopup()">
          {{'POLL-DASHBOARD.HEADBAND.HOTLINE' | translate}}
        </button>
        <ng-template #hotline>
          <p class="banner-text hotline-display">
            {{'POLL-DASHBOARD.HEADBAND.PHONE' | translate}}
          </p>
        </ng-template>

      <span class="right-container">
        <img src="assets/profile.svg" style="cursor: pointer; margin-left: 20px;" loading="lazy"  (click)="goToProfile()">
        <p class="banner-text" *ngIf="licenseTime == 'D'">
          {{accountName}} - {{'POLL-DASHBOARD.HEADBAND.EVENTS' | translate}}
        </p>
        <p class="banner-text" *ngIf="licenseTime == 'DM'">
          {{accountName}} - {{eventCounter}} {{'POLL-DASHBOARD.HEADBAND.PACK' | translate}}
        </p>
        <p class="banner-text" *ngIf="licenseTime == 'Y'">
          {{accountName}} - {{'POLL-DASHBOARD.HEADBAND.YEARLY' | translate}}
        </p>
        <button *ngIf="maxVoters > 10 && !licencesReady" [ngClass]="['inactive-large-button']">{{'POLL-DASHBOARD.HEADBAND.LOADING' | translate}}</button>
        <button *ngIf="maxVoters > 10 && licencesReady" [ngClass]="['green-button', 'button-change']"
          (click)="changeSubscription()">{{'POLL-DASHBOARD.HEADBAND.CHANGE' | translate}}</button>
        <img src="assets/power_settings_new_24px.svg" (click)="logout()" loading="lazy"
        style="cursor: pointer; margin-left: 20px; height: 24px; width:24px;">
      </span>
    </div>
  </div>
  <div class="left-header">
    <img src="assets/v8te.png" id="logo" data-cy="back-btn" (click)="navigateHome()">
    <div class="title-container">
      <div class="title-container-sub">
        <img class="arrow" (click)="navigateArrow()" src="assets/arrow-left.svg" style="cursor: pointer;">
        <h3 class="title" data-cy="event-title-ongoing">{{title}}</h3>
      </div>
      <img src="assets/info_icon.svg" id="info-icon" (click)="navigateHome()">
      <p class="poll-info" data-cy="details-ongoing">
        <span *ngIf="isLive">{{'POLL-CREATION.FORM.LABEL-LIVE' | translate}}</span>
        <span *ngIf="!isLive">{{'POLL-CREATION.FORM.LABEL-PLANNED' | translate}}</span>,
        <span *ngIf="!isPublic">{{'COMMON.LOW-SECRET' | translate}}</span>
        <span *ngIf="isPublic">{{'COMMON.LOW-PUBLIC' | translate}}</span>,
        <span *ngIf="SMSOption && !is2FA">{{'COMMON.LOW-MAIL-SMS' | translate}}</span>
        <span *ngIf="SMSOption && is2FA">{{'COMMON.LOW-2FA' | translate}}</span>
        <span *ngIf="!SMSOption">{{'COMMON.LOW-MAIL' | translate}}</span>
        <span *ngIf="isAssessors">{{'COMMON.ASS-INVITE' | translate}}</span>
      </p>
      <p></p>
    </div>
  </div>
  <div></div>
</div>

<!-- Popups si l'utilisateur a déjà un forfait -->
<vc-warning-popup class="YA" *ngIf="shoulDisplayChangePopup && smsAdditionalOption" [isAssoc]="clientType == 'assoc'"
title="{{'PRICE-DETAILS.SMS-ON-CHANGE' | translate}}" [noSMSButton]="true" [separator]="true" [SMSOffers]="smsAdditionalOption"
(SMSOptionEmitter)="setSMSOption($event)" (endDetailsPopup)="dismissInitialPopup()">
</vc-warning-popup>

<!-- Popup to select an SMS option with the package -->
<vc-warning-popup class="SMS" *ngIf="smsAdditionalOption && !shoulDisplayChangePopup" [SMSOffers]="smsAdditionalOption" title="{{'PRICE-DETAILS.SMS-TITLE' | translate}}" [separator]="true"
    [noSMSButton]="true" (SMSOptionEmitter)="setSMSOption($event)" (endDetailsPopup)="dismissInitialPopup()" [isAssoc]="isAssoc">
</vc-warning-popup>

<!-- Popups si l'utilisateur souhaite acheter un forfait -->
<vc-warning-popup class="hotline" *ngIf="hotlinePopup" [isAssoc]="clientType == 'assoc'"
title="{{'HOTLINE.HOTLINE-TEXT' | translate}}" [cancelButton]="true" [separator]="true" [hotline]="true"
(hotlineOptionEmitter)="licenseService.setHotlineOption($event, isAssoc)" (endDetailsPopup)="dismissInitialPopup()" [licenseTime]="licenseTime">
</vc-warning-popup>
