<div id="page-container">
  <div id="content-wrap">
    <div class="fullScreen">
      <div class="account-header">
        <div *ngIf="maxVoters === 10" style="display: flex; justify-content: space-between; height: 100%; align-items: center;">
          <p *ngIf="!displayHotline else hotline" class="banner-text">{{'POLL-DASHBOARD.HEADBAND.MESSAGE' | translate}}</p>
          <ng-template #hotline>
            <p class="banner-text hotine-display">
              {{'POLL-DASHBOARD.HEADBAND.PHONE' | translate}}
            </p>
          </ng-template>
          <div style="height: 100%; margin-right: 24px; display: flex; align-items: center;">
            <img src="assets/profile.svg" style="cursor: pointer; margin-left: 20px; margin-right: 8px; height: 24px; width:24px;" (click)="goToProfile()">
            <span class="banner-text" style="margin: 0;">{{accountName}} {{sms}}</span>
            <button *ngIf="!licencesReady" class="button-change" [ngClass]="['inactive-large-button']">{{'POLL-DASHBOARD.HEADBAND.LOADING' | translate}}</button>
            <button *ngIf="licencesReady"class="button-change" [ngClass]="['green-button', 'button-change']" (click)="buyCredits()">{{'POLL-DASHBOARD.HEADBAND.BUY' | translate}}</button>
            <img src="assets/power_settings_new_24px.svg" (click)="logout()"
              style="cursor: pointer; margin-left: 20px; height: 24px; width:24px;">
          </div>
        </div>
        <div *ngIf="maxVoters > 10" style="margin-right: 24px; height: 100%; display: flex; justify-content: space-between;align-items: center;">
          <button *ngIf="!hasHotline else hotline" class="button-change" [ngClass]="['green-button', 'button-change']"
           (click)="triggerHotlinePopup()">
            {{'POLL-DASHBOARD.HEADBAND.HOTLINE' | translate}}
          </button>
          <ng-template #hotline>
            <p class="banner-text hotline-display">
              {{'POLL-DASHBOARD.HEADBAND.PHONE' | translate}}
            </p>
          </ng-template>

          <div class="right-container">
            <img src="assets/profile.svg" style="cursor: pointer; margin-left: 20px; margin-right: 8px; height: 24px; width:24px;" (click)="goToProfile()">
            <span *ngIf="licenseTime == 'D' && remainingLicense > 0" class="banner-text">{{accountName}} - {{'POLL-DASHBOARD.HEADBAND.EVENTS' |
              translate}} {{sms}}</span>
              <span *ngIf="licenseTime == 'D' && remainingLicense == 0" class="banner-text">{{accountName}} - {{remainingLicense}}
                {{'POLL-DASHBOARD.HEADBAND.REMAINING' | translate}} {{sms}}</span>
            <span *ngIf="licenseTime == 'DM' && remainingLicense > 0" class="banner-text">{{accountName}} - {{remainingLicense}} {{'POLL-DASHBOARD.HEADBAND.PACK' |
              translate}} {{sms}}</span>
              <span *ngIf="licenseTime == 'DM' && remainingLicense == 0" class="banner-text">{{accountName}} - {{remainingLicense}}
                {{'POLL-DASHBOARD.HEADBAND.REMAINING' | translate}} {{sms}}</span>
            <span *ngIf="licenseTime == 'Y'" class="banner-text">{{accountName}} - {{'POLL-DASHBOARD.HEADBAND.YEARLY' |
              translate}} {{sms}}</span>
            <button *ngIf="maxVoters > 10 && !licencesReady" [ngClass]="['inactive-large-button']">{{'POLL-DASHBOARD.HEADBAND.LOADING' | translate}}</button>
            <button *ngIf="maxVoters > 10 && licencesReady" [ngClass]="['green-button', 'button-change']"
              (click)="changeSubscription()">{{'POLL-DASHBOARD.HEADBAND.CHANGE' | translate}}</button>
            <img src="assets/power_settings_new_24px.svg" (click)="logout()"
              style="cursor: pointer; margin-left: 20px; height: 24px; width:24px;">
          </div>
        </div>
      </div>
      <img src="assets/v8te.png" id="logo">
    </div>
    <div class="title-holder" *ngIf="plannedPolls.length > 0 || runningPolls.length > 0 || closedPolls.length > 0 || draftPolls.length > 0 else fend">
      <h1 class="description">{{'POLL-DASHBOARD.TITLE' | translate}}</h1>
      <!-- <button id="new-poll" class="blue-button" (click)=newPoll()>
          +&nbsp;&nbsp;&nbsp;{{'POLL-DASHBOARD.NEW-POLL' | translate}}
      </button> -->
      <blue-button [buttonPlus]="true" [buttonText]="'POLL-DASHBOARD.NEW-POLL' | translate"
      (createPollEvent)="newPoll()">
      </blue-button>
    </div>
    <ng-template #fend>
      <div class="title-holder fend" style="margin-top: 86px">
        <blue-button [buttonPlus]="true" [buttonText]="'POLL-DASHBOARD.NEW-POLL' | translate"
          (createPollEvent)="newPoll()">
        </blue-button>
        <!-- <button id="new-poll" class="blue-button" (click)=newPoll()>
            {{'POLL-DASHBOARD.NEW-POLL' | translate}}
        </button> -->
      </div>
    </ng-template>


    <!-- Research feature, disable for the moment -->
    <!-- <div class="title-holder search">
      <input type="text" name="search_field" id="search_field" placeholder="Rechercher un scrutin" (keyup)="onResearchInput($event)">
    </div> -->
    <div id="column-holder" *ngIf="plannedPolls.length != 0 || runningPolls.length != 0 || closedPolls.length != 0|| draftPolls.length != 0">
      <vc-poll-column [isExpanded]="isExpanded[1]" [polls]="draftPolls" [enableDeletion]="true"
      pollTitle="{{'POLL-DASHBOARD.DRAFTS' | translate}}" [category]="0"></vc-poll-column>
      <vc-poll-column [isExpanded]="isExpanded[1]" [polls]="plannedPolls" [enableDeletion]="true"
      pollTitle="{{'POLL-DASHBOARD.PLANNED-POLLS' | translate}}" [category]="1" [super]="auth.getSuperAdmin(accountName)"></vc-poll-column>
      <vc-poll-column [isExpanded]="isExpanded[1]" [polls]="runningPolls"
      pollTitle="{{'POLL-DASHBOARD.ONGOING-POLLS' | translate}}" [category]="2" [super]="auth.getSuperAdmin(accountName)"></vc-poll-column>
      <vc-poll-column [isExpanded]="isExpanded[1]" [polls]="closedPolls"
      pollTitle="{{'POLL-DASHBOARD.FINISHED-POLLS' | translate}}" [category]="3"></vc-poll-column>
    </div>

    <div class="title-holder-sec">
      <h2 class="sec-title">{{'POLL-DASHBOARD.FIRST-STEP' | translate}}</h2>
    </div>
    <div class="title-holder-sec">
      <div class=info-button (click)="seeVideos()">
        <img class="info-image" src="assets/guide-video.svg">
        <div>
          <h4 class="info-line">{{'POLL-DASHBOARD.INFO-VIDEO' | translate}}</h4>
          <h4 class="info-link">{{'POLL-DASHBOARD.VIDEO-LINK' | translate}}</h4>
        </div>
      </div>
      <div class=info-button (click)="downloadGuide()">
        <img class="info-image" src="assets/guide-guides.svg">
        <div>
          <h4 class="info-line">{{'POLL-DASHBOARD.INFO-GUIDE' | translate}}</h4>
          <h4 class="info-link">{{'POLL-DASHBOARD.GUIDE-LINK' | translate}}</h4>
        </div>
      </div>
    </div>
    <div class="title-holder-tert">
      <div class=info-button (click)="downloadDocuments()">
        <img class="info-image" src="assets/guide-documents.svg">
        <div>
          <h4 class="info-line">{{'POLL-DASHBOARD.INFO-DOCS' | translate}}</h4>
          <h4 class="info-link">{{'POLL-DASHBOARD.DOCS-LINK' | translate}}</h4>
        </div>
      </div>
      <div class=info-button (click)="contactMail()">
        <img class="info-image" src="assets/guide-questions.svg">
        <div>
          <h4 class="info-line">{{'POLL-DASHBOARD.INFO-CONTACT' | translate}}</h4>
          <h4 class="info-link">{{'POLL-DASHBOARD.CONTACT-LINK' | translate}}</h4>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Popup to display more options -->
<vc-warning-popup class="MORE" *ngIf="moreOption" title="{{'PRICE-DETAILS.TITLE' | translate}}" [callButton]="true" [separator]="false"
  [cancelButton]="true" (endDetailsPopup)="openOffers($event)" [isAssoc]="isAssoc" [index]="selectedOffer">
</vc-warning-popup>

<!-- Popup to get contacted by v8te for more information -->
<vc-warning-popup class="CONTACTED" *ngIf="getContacted" [index]="-1" (endDetailsPopup)="dismissInitialPopup()"
    title="{{'DETAILS-CONTACT.TITLE' | translate}}" [phoneInput]="true" [understoodEndButton]="true"
   (contactPhone)="getContactPhone($event)"></vc-warning-popup>

<!-- Popup to confirm that the user will be contacted -->
<vc-warning-popup class="CONFIRMCOONTACT" *ngIf="confirmContact" title="{{'DETAILS-CONTACT.THANK' | translate}}"
  firstText="{{'DETAILS-CONTACT.CONFIRMATION' | translate}}" secondText="{{'DETAILS-CONTACT.CONTACT' | translate}}"
  [understoodEndButton]="true" (endDetailsPopup)="dismissInitialPopup()" [separator]="false"></vc-warning-popup>

<!-- Popups pour acheter une offre -->
<vc-offers-popup *ngIf="shouldBuyCredits" [isAssoc]="isAssoc" source="{{'dashboard'}}" (freeTrial)="newPoll()"
  (isClosed)="dismissPayPopup($event)"  (moreInfoEmitter)="displayMoreInfo($event)"
  (displaySMSEmitter)="displaySMSOption($event)" (selectedOffer)="licenseService.buyOffer($event)"></vc-offers-popup>

<!-- Popups première connexion -->
<!-- <vc-warning-popup class="first-connection" *ngIf="shouldDisplayPopup" (endDetailsPopup)="dismissInitialPopup()"
  title="{{'WELCOME-POPUP.TITLE' | translate}}" firstText="{{'WELCOME-POPUP.LIMIT' | translate}}"
  secondText="{{'WELCOME-POPUP.CONTACT' | translate}}" [understoodButton]="true"></vc-warning-popup> -->

  <vc-warning-popup class="confirm-edition-popup" *ngIf="editionDonePopup" (endDetailsPopup)="dismissInitialPopup()"
  title="{{'EDIT-POPUP.CONGRATS' | translate}}" [understoodButton]="true"></vc-warning-popup>

<!-- Popup to select an SMS option with the package -->
<vc-warning-popup class="SMS" *ngIf="smsAdditionalOption && !shoulDisplayChangePopup" [SMSOffers]="smsAdditionalOption" title="{{'PRICE-DETAILS.SMS-TITLE' | translate}}" [separator]="true"
    [noSMSButton]="true" (SMSOptionEmitter)="setSMSOption($event)" (endDetailsPopup)="dismissInitialPopup()" [isAssoc]="isAssoc">
</vc-warning-popup>

<!-- Popups confirmation achat -->
<div *ngIf="shouldDisplayNewLicences">
  <vc-warning-popup class="PURCHASE" (endDetailsPopup)="cancelNewLicencesPopup()" title="{{'POLL-PAYMENT.CONGRATS' | translate}}"
    firstText="{{'POLL-PAYMENT.UPDATE' | translate}}" secondText="{{'POLL-PAYMENT.DESCRIPTION' | translate}}"
    thirdText="{{'POLL-PAYMENT.NUMBER-1' | translate}} {{newLicences[0].nb}} {{'POLL-PAYMENT.NUMBER-2' | translate}}" [understoodButton]="true"></vc-warning-popup>
</div>

<!-- Popups confirmation achat hotline -->
<div *ngIf="hotlineConfirmation">
  <vc-warning-popup class="hotline-popup" (endDetailsPopup)="cancelNewLicencesPopup()"
  firstText="{{'POLL-PAYMENT.HOTLINE' | translate}}" [understoodButton]="true"></vc-warning-popup>
</div>

<!-- Popups si l'utilisateur a déjà un forfait -->
<vc-warning-popup class="YA" *ngIf="shoulDisplayChangePopup && smsAdditionalOption" [isAssoc]="clientType == 'assoc'"
title="{{'PRICE-DETAILS.SMS-ON-CHANGE' | translate}}" [noSMSButton]="true" [separator]="true" [SMSOffers]="smsAdditionalOption"
(SMSOptionEmitter)="setSMSOption($event)" (endDetailsPopup)="dismissInitialPopup()">
</vc-warning-popup>

<!-- Popups si l'utilisateur souhaite acheter un forfait -->
<vc-warning-popup class="hotline" *ngIf="hotlinePopup" [isAssoc]="clientType == 'assoc'"
title="{{'HOTLINE.HOTLINE-TEXT' | translate}}" [cancelButton]="true" [separator]="true" [hotline]="true"
(hotlineOptionEmitter)="licenseService.setHotlineOption($event, isAssoc)" (endDetailsPopup)="dismissInitialPopup()" [licenseTime]="licenseTime">
</vc-warning-popup>
